<template>
  <div>
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <h1>Report Group Categories</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-chip
            v-for="(collection, index) in selectedCollections"
            :key="`collections-selected-chip-${index}`"
            class="ma-1"
            color="teal"
            text-color="white"
            small
            label
            close
            @click:close="selectedCollections.splice(index, 1)">
            {{ collection.name }}
          </v-chip>
        </v-col>
      </v-row>
      <v-row class="report-wrap">
        <v-col
          md="6"
          sm="12"
          cols="12">
          <v-autocomplete
            v-model="selectedCollections"
            :items="collections"
            :search-input.sync="collectionSearch"
            :apply="() => {}"
            item-text="name"
            return-object
            multiple
            dense
            filled
            solo
            small-chips
            hide-details
            hide-selected>
            <template v-slot:append>
              <v-icon x-small>
                mdi-tag
              </v-icon>
            </template>
            <template v-slot:selection>
              <span></span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          md="6"
          sm="12"
          cols="12"
          class="d-flex align-end">
          <date-picker v-model="dateSelected" />
          <v-btn
            color="primary"
            class="ml-2"
            @click="fetchReport">
            Go
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-4 mt-5 text-sm">
      <div class="d-flex align-center">
        <h2>Sales</h2>
        <v-icon
          class="ml-2 cs-pointer"
          color="success"
          @click="exportCsvSale">
          mdi-microsoft-excel
        </v-icon>
      </div>
      <v-data-table
        :headers="salesReportHeaders"
        :items="saleReport"
        :items-per-page="-1"
        :loading="reportLoding"
        dense
        hide-default-footer>
        <template v-slot:[`item`]="{ item, headers, index }">
          <tr>
            <td
              v-for="(head, ind) of headers"
              :key="`sales${index}${ind}`"
              :style="`text-align: ${head.align};`">
              <span v-if="head.value === 'warehouse'">
                {{ item.warehouse }}
              </span>
              <span v-else-if="item[head.value]">
                {{ item[head.value] | showNumberFormat() }}
              </span>
              <span v-else>
                0
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="pa-4 mt-5 text-sm">
      <div class="d-flex align-center">
        <h2>Stock In Value</h2>
        <v-icon
          class="ml-2 cs-pointer"
          color="success"
          @click="exportCsvStockIn">
          mdi-microsoft-excel
        </v-icon>
      </div>
      <v-data-table
        :headers="salesReportHeaders"
        :items="stockInReport"
        :items-per-page="-1"
        :loading="stockInLoading"
        dense
        hide-default-footer>
        <template v-slot:[`item`]="{ item, headers, index }">
          <tr>
            <td
              v-for="(head, ind) of headers"
              :key="`stockin${index}${ind}`"
              :style="`text-align: ${head.align};`">
              <span v-if="head.value === 'warehouse'">
                {{ item.warehouse }}
              </span>
              <span v-else-if="item[head.value]">
                {{ item[head.value].sumStockInValue | showNumberFormat() }} ({{ item[head.value].sumStockIn | showNumberFormat() }})
              </span>
              <span v-else>
                0 (0)
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="pa-4 mt-5 text-sm">
      <div class="d-flex align-center">
        <h2>Stock Out Value</h2>
        <v-icon
          class="ml-2 cs-pointer"
          color="success"
          @click="exportCsvStockOut">
          mdi-microsoft-excel
        </v-icon>
      </div>
      <v-data-table
        :headers="salesReportHeaders"
        :items="stockOutReport"
        :items-per-page="-1"
        :loading="stockOutLoading"
        dense
        hide-default-footer>
        <template v-slot:[`item`]="{ item, headers, index }">
          <tr>
            <td
              v-for="(head, ind) of headers"
              :key="`stockout${index}${ind}`"
              :style="`text-align: ${head.align};`">
              <span v-if="head.value === 'warehouse'">
                {{ item.warehouse }}
              </span>
              <span v-else-if="item[head.value]">
                {{ item[head.value].sumStockOutValue | showNumberFormat() }} ({{ item[head.value].sumStockOut | showNumberFormat() }})
              </span>
              <span v-else>
                0 (0)
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ProductCollectionProvider from '@/resources/ProductCollectionProvider'
import ProductGroupCategoryProvider from '@/resources/ProductGroupCategoryProvider'
import NewReportGroupCategoriesProvider from '@/resources/NewReportGroupCategoriesProvider'
import { ExportToCsv } from 'export-to-csv'
import DatesPicker from '../components/DatesPicker.vue'

const ProductCollectionService = new ProductCollectionProvider()
const ProductGroupCategoryService = new ProductGroupCategoryProvider()
const NewReportGroupCategoriesService = new NewReportGroupCategoriesProvider()
export default {
  components: {
    DatePicker: DatesPicker
  },
  data () {
    return {
      selectedCollections: [],
      saleReport: [],
      stockInReport: [],
      stockOutReport: [],
      collectionSearch: '',
      collections: [],
      loading: false,
      reportLoding: false,
      stockInLoading: false,
      stockOutLoading: false,
      salesReportHeaders: [
        { text: 'สาขา', value: 'warehouse', width: 250 }
      ],
      dateSelected: [this.$dayjs().format(), this.$dayjs().format()]
    }
  },
  watch: {
    selectedCollections (val) {
      this.selectedCollections = val
      this.collectionSearch = ''
    }
  },
  async mounted () {
    await Promise.all([this.getGroupCategories(), this.getCollections()])
  },
  methods: {
    fetchReport () {
      this.resetData()
      this.fetchSaleReportWarehouse()
      this.fetchStockInReport()
      this.fetchStockOutReport()
    },
    resetData () {
      this.saleReport = []
      this.stockInReport = []
      this.stockOutReport = []
    },
    exportCsvSale () {
      const date1 = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const date2 = this.$dayjs(this.dateSelected[1])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const options = {
        filename: `sales_${date1}-${date2}_${this.selectedCollections.map((d) => d.name).join('_')}`,
        showLabels: false,
        useKeysAsHeaders: true
      }

      const data = this.saleReport.map((sr) => {
        const payload = {}
        this.salesReportHeaders.forEach((sh) => {
          payload[sh.text] = sr[sh.value] || ''
        })
        return payload
      })

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    exportCsvStockIn () {
      const date1 = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const date2 = this.$dayjs(this.dateSelected[1])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')

      const data = []
      this.stockInReport.forEach((re) => {
        const payload = {}
        for (const val of this.salesReportHeaders) {
          if (val.value !== 'warehouse') {
            payload[`STOCK IN ${val.text}`] = re[val.value]?.sumStockIn || ''
            payload[`STOCK IN VALUE ${val.text}`] = re[val.value]?.sumStockInValue || ''
          } else {
            payload['สาขา'] = re.warehouse
          }
        }

        data.push(payload)
      })

      const options = {
        filename: `stock-in_${date1}-${date2}_${this.selectedCollections.map((d) => d.name).join('_')}`,
        showLabels: false,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    exportCsvStockOut () {
      const date1 = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const date2 = this.$dayjs(this.dateSelected[1])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const data = []
      this.stockOutReport.forEach((re) => {
        const payload = {}
        for (const val of this.salesReportHeaders) {
          if (val.value !== 'warehouse') {
            payload[`STOCK OUT ${val.text}`] = re[val.value]?.sumStockOut || ''
            payload[`STOCK OUT VALUE ${val.text}`] = re[val.value]?.sumStockOutValue || ''
          } else {
            payload['สาขา'] = re.warehouse
          }
        }

        data.push(payload)
      })
      const options = {
        filename: `stock-out_${date1}-${date2}_${this.selectedCollections.map((d) => d.name).join('_')}`,
        showLabels: false,
        useKeysAsHeaders: true
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    async fetchSaleReportWarehouse () {
      this.saleReport = []
      try {
        this.reportLoding = true

        const [dataWh, dataOnline] = await Promise.all([
          NewReportGroupCategoriesService.getSaleCategoriesWarehouse({
            gwCollections: this.selectedCollections.map((d) => d.id),
            startDate: this.$dayjs(this.dateSelected[0])
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .format(),
            endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .format()
          }),
          NewReportGroupCategoriesService.getSaleCategories({
            gwCollections: this.selectedCollections.map((d) => d.id),
            startDate: this.$dayjs(this.dateSelected[0])
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .format(),
            endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .format()
          })
        ])

        this.saleReport = dataWh.data.map((d) => {
          let all = 0
          const numbers = {}
          for (const [key, val] of Object.entries(d)) {
            if (key !== 'warehouse') {
              numbers[key] = val
              all += val
            }
          }

          return { warehouse: d.warehouse.name, ...numbers, all }
        })

        const newData = dataOnline.data.map((d) => {
          let all = 0
          const numbers = {}
          for (const [key, val] of Object.entries(d)) {
            if (key !== 'channel') {
              numbers[key] = val
              all += val
            }
          }

          return { warehouse: d.channel, ...numbers, all }
        })

        this.saleReport.push(...newData)

        const headers = this.salesReportHeaders
          .map((v) => v.value)
          .filter((v) => v !== 'warehouse')

        headers.push('all')

        const grandTotal = {
          warehouse: 'Total'
        }

        headers.forEach((key) => {
          grandTotal[key] = this.saleReport.reduce((total, report) => total + report[key], 0)
        })

        this.saleReport.push(grandTotal)
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[GET SALE]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.reportLoding = false
      }
    },
    async fetchStockInReport () {
      this.stockInReport = []
      try {
        this.stockInLoading = true
        const { data } = await NewReportGroupCategoriesService.getStockInCategories({
          gwCollections: this.selectedCollections.map((d) => d.id),
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })

        const mappedStocks = data.map((d) => {
          let sumStockIn = 0
          let sumStockInValue = 0
          const numbers = {}
          for (const [key, val] of Object.entries(d)) {
            if (key !== 'warehouse') {
              numbers[key] = {
                sumStockIn: val?.sumStockIn || 0,
                sumStockInValue: val?.sumStockInValue || 0
              }
              sumStockIn += val?.sumStockIn || 0
              sumStockInValue += val?.sumStockInValue || 0
            }
          }

          return { warehouse: d.warehouse.name, ...numbers, all: { sumStockIn, sumStockInValue } }
        })

        const headers = this.salesReportHeaders
          .map((v) => v.value)
          .filter((v) => v !== 'warehouse')

        headers.push('all')

        const grandTotal = {
          warehouse: 'Total'
        }

        headers.forEach((key) => {
          grandTotal[key] = mappedStocks.reduce((total, report) => {
            const tmpTotal = { ...total }

            tmpTotal.sumStockIn += report[key]?.sumStockIn || 0
            tmpTotal.sumStockInValue += report[key]?.sumStockInValue || 0

            return tmpTotal
          }, {
            sumStockIn: 0,
            sumStockInValue: 0
          })
        })

        mappedStocks.push(grandTotal)

        this.stockInReport = mappedStocks
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[GET SALE]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.stockInLoading = false
      }
    },
    async fetchStockOutReport () {
      this.stockOutReport = []
      try {
        this.stockOutLoading = true
        const { data } = await NewReportGroupCategoriesService.getStockOutCategories({
          gwCollections: this.selectedCollections.map((d) => d.id),
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })

        const mappedStocks = data.map((d) => {
          let sumStockOut = 0
          let sumStockOutValue = 0
          const numbers = {}
          for (const [key, val] of Object.entries(d)) {
            if (key !== 'warehouse') {
              numbers[key] = {
                sumStockOut: val?.sumStockOut || 0,
                sumStockOutValue: val?.sumStockOutValue || 0
              }
              sumStockOut += val?.sumStockOut || 0
              sumStockOutValue += val?.sumStockOutValue || 0
            }
          }

          return { warehouse: d.warehouse.name, ...numbers, all: { sumStockOut, sumStockOutValue } }
        })

        const headers = this.salesReportHeaders
          .map((v) => v.value)
          .filter((v) => v !== 'warehouse')

        headers.push('all')

        const grandTotal = {
          warehouse: 'Total'
        }

        headers.forEach((key) => {
          grandTotal[key] = mappedStocks.reduce((total, report) => {
            const tmpTotal = { ...total }

            tmpTotal.sumStockOut += report[key]?.sumStockOut || 0
            tmpTotal.sumStockOutValue += report[key]?.sumStockOutValue || 0

            return tmpTotal
          }, {
            sumStockOut: 0,
            sumStockOutValue: 0
          })
        })

        mappedStocks.push(grandTotal)

        this.stockOutReport = mappedStocks
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[GET SALE]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.stockOutLoading = false
      }
    },
    async getGroupCategories () {
      try {
        this.loading = true

        const { data } = await ProductGroupCategoryService.getAll({
          page: 1,
          limit: 999,
          sortBy: 'priority',
          sortOrder: 'asc'
        })

      const salesHeaders = []
      data.results.forEach((d) => {
        salesHeaders.push({ text: d.name, value: `${d.name.replace(/ /g, '').toLowerCase()}`, width: 180, align: 'center' })
      })

      this.salesReportHeaders.push(
        ...salesHeaders,
        { text: 'Others', value: 'others', width: 180, align: 'center' },
        { text: 'All', value: 'all', width: 180, align: 'center' }
      )
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[GETTING GROUP CATEGORIES]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getCollections () {
      try {
        this.loading = true

        const { data } = await ProductCollectionService.getAll({
          page: 1,
          limit: 999
        })

        this.collections = data.results
      } catch (error) {
        console.error('getCollections', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING GROUP COLLECTIONS]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.text-sm {
  font-size: 12px !important;
}
</style>
