import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

export default class ReportGroupCategoriesProvider extends HttpRequest {
  getSaleCategoriesWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('new-report-group-categories/sales-pos', query)
  }

  getSaleCategories (query) {
    this.setHeader(getAuthToken())
    return this.get('new-report-group-categories/sales', query)
  }

  getStockInCategories (query) {
    this.setHeader(getAuthToken())
    return this.get('new-report-group-categories/stock-in', query)
  }

  getStockOutCategories (query) {
    this.setHeader(getAuthToken())
    return this.get('new-report-group-categories/stock-out', query)
  }
}
